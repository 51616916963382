body {
  /*************************************************
      ESTILOS COMUM A TODOS OS INPUTS
   ************************************************/

  // estilo de erro ao preencher input
  input.ng-touched, select.ng-touched, ng-autocomplete.ng-touched, textarea.ng-touched {
    &.ng-invalid {
      border-color: red !important;
    }
  }

  .mat-select-panel, .cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 9999 !important;
  }

  .mat-form-field-appearance-outline:not(.preserve-style-material) {
    .mat-form-field-infix {
      padding: .2rem !important;
      margin: .2rem !important;
    }
  }

  .form-control, .form-control-lg, .form-select {
    border: 1px solid rgba(128, 128, 128, 0.25);
  }
}
