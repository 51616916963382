

.pointer {
  cursor: pointer;
}

.f-bold {
  font-weight: bolder;
}

.footer-page {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: .5rem 2rem .5rem .5rem;
  display: inline-flex;
  justify-content: flex-end;
}
