
@import "node_modules/@datagrupo/dg-ng-util/assets/scss/dg-ng-util";

@import "assets/scss/components/tables";
@import "assets/scss/components/sidebar-menu";
@import "./assets/scss/components/inputs";
@import "assets/scss/components/esteira-pedidos";
@import "assets/scss/components/card";


@import "assets/scss/util";
@import "assets/scss/variables";
@import "assets/scss/typography";


.page-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  top: auto;
  background: #eaeaea;
  padding: .5rem 1rem;
  display: inline-flex;
  justify-content: flex-end;
}
