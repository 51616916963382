dg-sidebar-menu {
  #sidebar-wrapper {
    background: #212529;
  }
}
.sidebar-logo {

  img {
    border-radius: 50% !important;
    max-height: 150px;
    max-width: 150px;
  }
}
