.card.card-app {
  //.card-header, .card-body, .card-footer {
  //  border: none;
  //}
  border: 1px solid #e1e4e7;
  border-top: solid 5px #649fd7;
  background: #FFFFFF;

  .card-footer {
    border-color: #e1e4e7;
  }

  .card-header {
    border-color: #e1e4e7;
    border-bottom: 2px solid #e1e4e7;
  }
}
