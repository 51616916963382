
.dg-table {
  width: 100%;
}

.default-table .dg-table, table.default-table {
  width: 100%;

  thead {
    tr {
      padding: .5rem .5rem;
      border-bottom: solid .1rem rgba(215, 207, 241, 0.6);
    }

    th {
      font-size: 11pt;

      &.th-actions {
        width: 100px;
        max-width: none;
      }
    }
  }

  tbody {
    tr {
      padding: .5rem .5rem;
      font-size: 11pt;
      color: #484848;
      border-bottom: solid .1rem rgba(215, 207, 241, 0.6);
      margin: 5rem 0 !important;
      transition: .2s;

      &:hover {
        transition: .5s;
        border-bottom: solid .1rem rgba(215, 207, 241, 1);
        background: rgba(225, 217, 250, 0.6);
        box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.1);
      }
    }

    td {
      color: #484848;
      text-align: start;
      font-weight: 500;
      padding: .25rem;
    }

    td.td-actions {
      justify-content: center;
      padding: 0 .5rem;

      .default-inline-actions-dg-table {
        display: inline-flex;
        justify-content: flex-end;

        >:not(:last-child) {
          margin-right: .3rem;
        }

        [class^="dg-table-action-"], [class*=" dg-table-action-"] {
          cursor: pointer;
          padding: .3rem;
          border-radius: 5px;
          border: solid 1px rgba(0,0,0,0);
          display: flex;
          justify-content: center;
          align-items: center;

          >i, >span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--dg-theme-primary-400);
            font-size: 11pt;
          }

          &:hover {
            border: solid 1px white;
            background-color: white;
          }
        }
      }
    }

    td.td-actions {
      .dg-table-action-editar {
        color: rgba(30, 144, 255, 0.7);

        &:hover {
          color: dodgerblue;
        }
      }
      .dg-table-action-excluir {
        color: rgba(255, 0, 0, 0.7);

        &:hover {
          color: red;
        }
      }
    }
  }
}
