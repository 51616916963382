

.container-esteira {}

.card.card-content-status-pedido-esteira {
  height: 100%;

  >.card-body {
    background: #eaeaea;
    padding: 0 .2rem .2rem .2rem;
  }
}
