
/**
    os marcadores de screen abaixo seguem o padrão do bootstrap.
    marcadores adicionais foram inseridos para capturar apenas partes
    específicas do grig que enteressar
 */
$xs-layout: 'only screen and (max-width: 575px)';
$sm-layout: 'only screen and (max-width: 767px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$xl-layout: 'only screen and (min-width: 1200px)';
$xxl-layout: 'only screen and (min-width: 1400px)';

/*
    marca o minimo de cada marcador
 */
$min-md-layout: 'only screen and (min-width: 768px)';
$min-lg-layout: 'only screen and (min-width: 992px)';
$min-lg-layout: 'only screen and (min-width: 992px)';
$min-xl-layout: 'only screen and (min-width: 1200px)';
$max-md-layout: 'only screen and (max-width: 991px)';
